import React, { useRef } from "react";
import { useState } from "react";

const Login = ({ okCallback }) => {
  const inputUserName = useRef(null);
  const inputPassword = useRef(null);
  const [error, setError] = useState(false);

  const doLogin = async () => {
    const data = {
      userName: inputUserName.current.value,
      password: inputPassword.current.value
    };

    const url = "https://magentabrokerageapi.azurewebsites.net/api/v1/Login?code=agpnKnaqGRu8paZ0DIrmDaLY54yTYv9OEW2GFqwxagtIcayx8YJBpw==";
    //const url = "http://localhost:7071/api/v1/Login";

    const result = await fetch(url, { method: "POST", body: JSON.stringify(data) });
    result
      .json()
      .then(result => {
        if (result.successful) {
          localStorage.setItem("MB_AccessTokenExpiry", result.expires);
          localStorage.setItem("MB_AccessToken", result.accessToken);

          okCallback();
        } else {
          setError(true);
        }
      })
      .catch(console.error);
  };

  return (
    <div className="min-vh-100 vw-100 bg-white d-flex align-items-center">
      <div className="card bg-magenta w-50 mx-auto border-2 shadow-lg">
        <div className="card-body">
          <h2 className="card-title mb-3">Login</h2>
          <form>
            <div className="form-group">
              <label htmlFor="userName">User Name</label>
              <input className="form-control" id="userName" required ref={inputUserName} />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" className="form-control" id="password" required ref={inputPassword} />
            </div>
            <button type="button" className="btn btn-outline btn-light" onClick={doLogin}>
              Submit
            </button>
            {error && (
              <div className="alert alert-dark mt-4" role="alert">
                Login Failed!
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
