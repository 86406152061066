import React, { useEffect, useState, useCallback } from "react";
import useInterval from "react-useinterval";
import MainPage from "./components/MainPage";
import Login from "./components/Login";

const MainComponent = () => {
  const [accessToken, setAccessToken] = useState(undefined);
  const [data, setData] = useState(null);

  const checkAccessToken = () => {
    const lsAccessTokenExpiry = localStorage.getItem("MB_AccessTokenExpiry");
    const lsAccessToken = localStorage.getItem("MB_AccessToken");

    if (lsAccessToken && new Date(lsAccessTokenExpiry) > new Date()) {
      setAccessToken(lsAccessToken);
    } else {
      localStorage.removeItem("MB_AccessTokenExpiry");
      localStorage.removeItem("MB_AccessToken");
      setAccessToken(undefined);
    }
  };

  const LoadData = useCallback(async () => {
    if (accessToken) {
      const url =
        "https://func-magentaapi-prod.azurewebsites.net/api/v1/brokerage";

      const result = await fetch(url, {
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      });
      result.json().then((result) => setData(result));
    }
  }, [accessToken]);

  useEffect(() => {
    checkAccessToken();
    LoadData();
  }, [LoadData]);

  useInterval(() => {
    checkAccessToken();
    LoadData();
  }, 180000);

  const loggedInCallback = () => {
    checkAccessToken();
    LoadData();
  };

  return (
    <>
      {accessToken ? (
        <MainPage data={data} />
      ) : (
        <Login okCallback={loggedInCallback} />
      )}
    </>
  );
};

export default MainComponent;
