import React from 'react';
import CountUpComponent from './CountUpComponent';

const DisplayComponent = ({ data, css }) => {
    return (
        data && data.map((item, index) =>
            <div key={index} className={`row ${css || ''}`}>
                <div className="col-7">{item.Name}</div>
                <div className="col-2 text-right"><CountUpComponent value={item.Count}></CountUpComponent></div>
                <div className="col-3 text-right">{item.Value > 0 && <CountUpComponent value={item.Value} prefix="£"></CountUpComponent>}</div>
            </div>
        )
    )
}
export default DisplayComponent;