import React, { useEffect } from 'react';
import { useCountUp } from 'react-countup';

const CountUpComponent = ({ value, prefix, suffix, decimals }) => {

  const { countUp, update } = useCountUp({
    start: 0,
    end: value || 0,
    prefix: prefix || '',
    suffix: suffix || '',
    decimals: decimals || 0,
    separator: ',',
    duration: 4,
  });

  useEffect(() => {
    update(value);
  }, [value, update]);

  return (
    <>{countUp}</>
  );
}; export default CountUpComponent;