import React from "react";
import DisplayComponent from "./DisplayComponent";
import CountUpComponent from "./CountUpComponent";

const MainPage = ({ data }) => {
  return (
    <>
      {data && (
        <div className="container-fluid">
          <div className="p-1 p-lg-3">
            <div className="text-right">
              <img src="/magenta-insurance-logo-white.svg" alt="Magenta Insurance Logo" />
            </div>
            <div className="row">
              <div className="col-12">
                <h2>New Business Brokerage</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-5">
                <DisplayComponent title="Quotes Today" data={data.newBusinessQuotesToday} />
                <DisplayComponent title="Live Today" data={data.newBusinessLiveToday} />
                <DisplayComponent title="Live MTD" data={data.newBusinessLiveMonthToDate} css="mt-2" />
                <DisplayComponent title="Live YTD" data={data.newBusinessLiveYearToDate} />
                <h5 className="mt-2">Month-to-date By Product</h5>
                <DisplayComponent title="New Business Live MTD By Product" data={data.newBusinessLiveMonthToDateByProduct} />
              </div>
              <div className="col-12 col-lg-6 offset-lg-1">
                <h5>Month-to-date By Underwriter</h5>
                <DisplayComponent title="New Business Live MTD By Underwriter" data={data.newBusinessLiveMonthToDateByUnderwriter} />
                <div className="row row-untouched mt-1 py-2 rounded">
                  <div className="col-7">Untouched</div>
                  <div className="col-2 text-right">
                    <CountUpComponent value={data.newBusinessLiveMonthToDateByUnderwriterUntouched.Count}></CountUpComponent>
                  </div>
                  <div className="col-3 text-right">
                    <CountUpComponent value={data.newBusinessLiveMonthToDateByUnderwriterUntouched.Value} prefix="£"></CountUpComponent>
                  </div>
                </div>
                <div className="row row-untouched mt-1 py-2 rounded">
                  <div className="col-6">Untouched Percentage</div>
                  <div className="col-3 offset-3 text-right">
                    <CountUpComponent value={data.untouchedPercentage} decimals="2" suffix="%"></CountUpComponent>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 mt-3">
                <h2>Renewals Brokerage</h2>
                <DisplayComponent title="Renewals Invited MTD" data={data.renewalsInvitedMonthToDate} />
                <DisplayComponent title="Renewals Live MTD" data={data.renewalsLiveMonthToDate} />
                <DisplayComponent title="Renewals Live YTD" data={data.renewalsLiveYearToDate} />
              </div>
              <div className="col-12 col-lg-6 offset-lg-1 mt-3">
                <h2>Total Brokerage</h2>
                <DisplayComponent title="Total Brokerage" data={data.brokerageLiveYearToDate} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainPage;
